/**
 * 获取文件目录（列出目录）
 */
export const getFileListApi = (params?: any) => useGet('/runtime/file/list', params)

/**
 * 下载文件
 */
export const getFileDownloadApi = (params: any) => useGet('/runtime/file/download', params)

/**
 * 删除文件或文件夹
 */
export const deleteFileApi = (params: any) => useDelete('/runtime/file', params)

/**
 * 创建文件夹
 */
export const addFileApi = (params: any) => usePost('/runtime/file/mkdir', params)

/**
 * 上传文件
 */
export function upFileApi(params: any, callBack: (percent: number) => void) {
  return usePost('/runtime/file/upload', params, { onUploadProgress(progressEvent) {
    if (progressEvent.total) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      callBack(percentCompleted)
    }
  } })
}

/**
 * 查询文件最大字节
 */
export const getFileMaxiMumByteApi = () => useGet('/runtime/file/max-file-size')

/**
 * 重命名文件或文件夹
 */
export const putFileNameApi = (params: any) => usePut('/runtime/file/rename', params)

/**
 * 列出指定目录下的资源
 */
export const getResourceApi = (directory?: string) => useGet('/deploy/backend-resource/directory-list', { directory })

/**
 * 查询资源的网络地址
 */
export const getAccessPathApi = (resourceId: any) => useGet('/deploy/backend-resource/access-path', { resourceId })
