<script setup lang="ts">
import type { Route } from 'ant-design-vue/es/breadcrumb/Breadcrumb'
import { getResourceApi } from '@/api/file'

const emit = defineEmits<{
  onChooseFile: [file: any] // named tuple syntax
}>()
const open = defineModel<boolean>('open')
const files = ref<any[]>([])

const routes = ref<Route[]>([
  {
    path: '/',
    breadcrumbName: '根目录',
  },
])

watch(open, async (newOpen) => {
  if (newOpen) {
    await refresh()
  }
  else {
    routes.value.splice(1)
  }
}, { immediate: true })

async function refresh(path?: string) {
  files.value = await getResourceApi(path)
}

async function onClick(route: any) {
  await refresh(route.path)
  const aimIndex = routes.value.findIndex(item => item.path === route.path)
  routes.value.splice(aimIndex + 1)
}

function onClickFile(file: any) {
  if (file.accessPath) {
    open.value = false
    emit('onChooseFile', file)
  }
  else {
    goIntoFolder(file)
  }
}

async function goIntoFolder(file: any) {
  routes.value.push({ path: `${file.resourceId}`, breadcrumbName: file.simpleName })
  await refresh(file.resourceId)
}
</script>

<template>
  <a-modal v-model:open="open" width="50%" :centered="true" :footer="null">
    <div mb-10px>
      <a-breadcrumb separator=">" :routes="routes">
        <template #itemRender="{ route, paths }">
          <span v-if="routes.indexOf(route) === routes.length - 1">
            {{ route.breadcrumbName }}
          </span>
          <span v-else cursor-pointer text-blue-500 @click="onClick(route)">
            {{ route.breadcrumbName }}
          </span>
        </template>
      </a-breadcrumb>
    </div>
    <div h-500px>
      <scroll>
        <a-row :gutter="[16, 16]">
          <a-col v-for="file in files" :key="file.resourceId" class="gutter-row" :span="4">
            <div class="file hover:bg-cyan-100/70" flex flex-col items-center cursor-pointer @click="onClickFile(file)">
              <div h-64px w-64px mb-10px>
                <FileIcon :file="file" />
              </div>
              <div class="line-clamp-3 text-ellipsis h-58px text-wrap w-full text-center" text-slate-900 text-12px>
                {{ file.simpleName }}
              </div>
            </div>
          </a-col>
        </a-row>
      </scroll>
    </div>
  </a-modal>
</template>

<style lang="scss" scoped>
.file{
    border-radius: 8px;
    padding: 12px;
}
</style>
